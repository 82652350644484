
import { defineComponent } from "vue";
import { signInAsGuest } from "@/common/common";
import PhoneSignInForm from "./Account/PhoneSignInForm.vue";
import { Capacitor } from "@capacitor/core";
export default defineComponent({
  data() {
    const goodToGo =
      !this.$store.state.signedInAsGuest && this.$store.state.userFirebaseUid;
    const native = Capacitor.isNativePlatform();
    return {
      goodToGo,
      native,
      loading: false as boolean,
      showPhone: false as boolean,
      shouldGo: false as boolean,
    };
  },

  components: {
    PhoneSignInForm,
  },
  methods: {
    // async googleSignIn() {
    //   this.$store.commit("setLoading", true);
    //   const upgrade = store.state.signedInAsGuest;
    //   await signInWithGoogle(upgrade);
    //   this.$store.commit("setLoading", false);
    // },
    // facebookSignIn() {
    //   const toast = useToast();
    //   toast("Facebook login not available yet. Check back soon!", {
    //     timeout: 2500,
    //     type: TYPE.INFO,
    //     hideProgressBar: true,
    //   });
    //   if (process?.env?.NODE_ENV !== "development") {
    //     this.$gtag.event("wants_fb_login", {
    //       eventLabel: "wants_fb_login",
    //     });
    //   }
    //   //   const upgrade = store.state.signedInAsGuest;
    //   //   await signInWithFacebook(upgrade);
    // },
    async signInAsGuest() {
      this.$store.commit("setLoading", true);
      await signInAsGuest();
      this.$store.commit("setLoading", false);
    },
  },
});
