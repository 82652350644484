
import { defineComponent } from "vue";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  linkWithCredential,
  PhoneAuthProvider,
  ConfirmationResult,
  PhoneAuthCredential,
  signInWithCredential,
  AuthErrorCodes,
} from "firebase/auth";
import store from "@/store/store";
import { TYPE, useToast } from "vue-toastification";
import {
  setFrontEndDetails,
  setFrontEndDetailsWithUidAndToken,
} from "@/services/AuthenticationService";
import router from "@/router/router";
import { runPostLinkingUpdates } from "@/common/common";
import {
  FirebaseAuthentication,
  User,
} from "@capacitor-firebase/authentication";
import { Capacitor } from "@capacitor/core";
const phoneNumberPattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const codePattern = /^\d{6}$/;
export default defineComponent({
  data() {
    const native = Capacitor.isNativePlatform();
    return {
      phone: "" as string,
      codeSent: false as boolean,
      code: "" as string,
      confirmationResult: null as ConfirmationResult | null,
      recaptchaVerified: false as boolean,
      recaptchaFailed: false as boolean,
      recaptchaVerificationToken: "" as string,
      recaptchaVerifier: null as RecaptchaVerifier | null,
      sendingCode: false as boolean,
      validating: false as boolean,
      verificationId: null as string,
      easyLoginUser: null as User | null,
      native,
    };
  },
  async mounted() {
    const fbAuth = getAuth();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.recaptchaVerifier = new RecaptchaVerifier(
      fbAuth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          this.recaptchaVerified = true;
        },
        "expired-callback": () => {
          this.recaptchaVerified = false;
        },
      }
    );

    // if (this.native) {
    //   // Attach `phoneVerificationCompleted` listener to be notified if phone verification could be finished automatically
    //   await this.attachPhoneListeners();
    // }
  },
  methods: {
    nativeSignInWithPhoneNumber() {
      return new Promise((resolve, reject) => {
        const cleanupListeners = () => {
          FirebaseAuthentication.removeAllListeners();
        };

        FirebaseAuthentication.addListener("phoneCodeSent", async (event) => {
          try {
            this.verificationId = event.verificationId;
            cleanupListeners();
            resolve(event.verificationId);
          } catch (error) {
            cleanupListeners();
            reject(error);
          }
        });

        FirebaseAuthentication.addListener(
          "phoneVerificationCompleted",
          (event) => {
            cleanupListeners();
            this.easyLoginUser = event.user;
            this.easyLogin();
            resolve(this.easyLoginUser); // Assuming you want to resolve after setting the user and calling easyLogin
          }
        );

        FirebaseAuthentication.signInWithPhoneNumber({
          phoneNumber: this.e164FormattedPhone,
        }).catch((error) => {
          cleanupListeners();
          console.log(error);
          reject(error);
        });
      });
    },
    // async attachPhoneListeners() {
    //   await FirebaseAuthentication.addListener(
    //     "phoneCodeSent",
    //     async (event) => {
    //       const toast = useToast();
    //       toast("Code sent!", {
    //         type: TYPE.SUCCESS,
    //         timeout: 5000,
    //       });
    //       this.verificationId = await event.verificationId;
    //     }
    //   );
    //   await FirebaseAuthentication.addListener(
    //     "phoneVerificationFailed",
    //     (event) => {
    //       const toast = useToast();
    //       toast(event.message, {
    //         type: TYPE.ERROR,
    //         timeout: 5000,
    //       });
    //     }
    //   );
    //   await FirebaseAuthentication.addListener(
    //     "phoneVerificationCompleted",
    //     async (event) => {
    //       // Update the user data property with the result
    //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //       // @ts-ignore
    //       this.user = (await event).user;
    //       await this.easyLogin();
    //     }
    //   );
    // },
    async sendCode() {
      try {
        this.sendingCode = true;
        if (this.native) {
          this.easyLoginUser = await this.nativeSignInWithPhoneNumber();
        } else {
          const auth = getAuth();
          if (this.codeSent) {
            this.recaptchaVerifier = new RecaptchaVerifier(
              auth,
              "recaptcha-container",
              {
                size: "invisible",
                callback: (response) => {
                  this.recaptchaVerified = true;
                },
                "expired-callback": () => {
                  this.recaptchaVerified = false;
                },
              }
            );
          }
          const appVerifier = this.recaptchaVerifier;

          this.confirmationResult = await signInWithPhoneNumber(
            auth,
            this.e164FormattedPhone,
            appVerifier
          );
        }
        this.codeSent = true;
      } catch (error) {
        const toast = useToast();
        toast(error.message, {
          type: TYPE.ERROR,
          timeout: 5000,
        });

        console.error(error);
      } finally {
        this.sendingCode = false;
      }
    },
    async confirmCode() {
      try {
        this.validating = true;
        const upgrade = store.state.signedInAsGuest;
        let currentUser = null;
        let shouldGoHome = false;
        const oldUid = store.state.userFirebaseUid ?? null;
        if (this.native) {
          if (!this.verificationId) {
            throw new Error(
              "Verification ID not found. Please refresh and try again."
            );
          }
          if (!this.code) {
            throw new Error(
              "Incomplete code. Please re-enter it and try again."
            );
          }
          const result = await FirebaseAuthentication.confirmVerificationCode({
            verificationId: this.verificationId,
            verificationCode: this.code ?? "",
          });

          const uid = result.user.uid;
          const token = (await FirebaseAuthentication.getIdToken())?.token;
          if (!uid || !token) {
            throw new Error(
              "An error occurred. Please try again. If the problem persists, please contact support at admin@gophrr.io "
            );
          }
          shouldGoHome = await setFrontEndDetailsWithUidAndToken(uid, token);
        } else {
          const auth = await getAuth();
          currentUser = auth.currentUser;
          let credential = null;

          const authCredential = PhoneAuthProvider.credential(
            this.confirmationResult.verificationId,
            this.code
          );

          if (upgrade && currentUser) {
            credential = await linkWithCredential(currentUser, authCredential);
          } else {
            credential = await signInWithCredential(auth, authCredential);
          }
          shouldGoHome = await setFrontEndDetails(credential);
          if (oldUid !== null && credential !== null) {
            await runPostLinkingUpdates(credential, oldUid);
          }
        }
        if (shouldGoHome) {
          router.push("/Dashboard");
        } else {
          const toast = useToast();
          toast("An error occurred. Please try again.", {
            type: TYPE.ERROR,
            timeout: 5000,
          });
        }
      } catch (error) {
        const toast = useToast();
        let message = error.message;
        if (error.code === AuthErrorCodes.PROVIDER_ALREADY_LINKED) {
          message =
            "This phone number is already linked to an account. Please sign in with that account.";
        } else if (error.code === AuthErrorCodes.INVALID_CODE) {
          message = "Invalid code. Please try again.";
        } else if (error.code === AuthErrorCodes.NEED_CONFIRMATION) {
          message =
            "This phone number is already linked to an account. Please sign out, then sign in with that account.";
        }
        console.error(error);
        toast(message, {
          type: TYPE.ERROR,
          timeout: 5000,
        });
      } finally {
        this.validating = false;
      }
    },
    async easyLogin() {
      const currentUser = this.easyLoginUser;
      const token = await FirebaseAuthentication.getIdToken();
      await setFrontEndDetailsWithUidAndToken(currentUser.uid, token.token);
      router.push("/Dashboard");
    },
  },
  computed: {
    e164FormattedPhone: {
      get() {
        let phone = this.phone.replace(/\D/g, "");
        if (phone.length >= 10) {
          return `+1${phone}`;
        }
        return this.phone;
      },
      set(value) {
        this.phone = value.replace(/\D/g, "");
      },
    },
    shouldPhoneBeDisabled() {
      return (
        this.getPhoneValidityClass.includes("invalid") ||
        this.getPhoneValidityClass === "form-control" ||
        this.$store.state.loading
      );
    },
    shouldCodeBeDisabled() {
      return (
        this.getCodeValidityClass.includes("invalid") ||
        this.getCodeValidityClass === "form-control" ||
        this.$store.state.loading
      );
    },
    getCodeValidityClass() {
      if (this.code.length === 0) return "form-control";
      return codePattern.test(this.code)
        ? "form-control is-valid"
        : "form-control is-invalid";
    },
    getPhoneValidityClass() {
      if (this.phone.length === 0) return "form-control";
      return phoneNumberPattern.test(this.phone)
        ? "form-control is-valid"
        : "form-control is-invalid";
    },
    validPhone() {
      return phoneNumberPattern.test(this.phone);
    },
    formattedPhone: {
      get() {
        let phone = this.phone.replace(/\D/g, "");
        if (phone.length >= 10) {
          return `+1 (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
            6,
            10
          )}`;
        }
        return this.phone;
      },
      set(value) {
        this.phone = value.replace(/\D/g, "");
      },
    },
  },
});
